<div class="main-header" [class.height-new]= "displayNewHomePage">
    <!-- HEADER LEFT -->
    <div class="header header-left">
        <span class="logo pe-ui-common-sprite pe-ui-common-sprite-hilti-logo"></span>
        <p class="application-name">
            <span class="main-application-name" l10n="Agito.Hilti.Profis3.Main.ApplicationName"></span>
            <span>&nbsp;</span>
            <span class="sub-application-name" l10n="Agito.Hilti.Profis3.Main.SubApplicationName"></span>
        </p>
        <div class="space"></div>

        <div class="border"></div>
    </div>

    <!-- HEADER CENTER -->
    <div #supportButtonMenu class="header header-center">
        <div class="header-center-left">
            <div class="header-center-button-wrapper"
                *ngIf="userSettingsService.isUpgradeLicenseAvailable() && !licenseService.floatingLimitReached && !displayNewHomePage">
                <button id="main-upgrade-to-premium-button" class="header-center-button"
                    type="button" (click)="upgradeLicense()">
                    <span class="header-center-button-text">{{comparisonRedirectHeaderText}}</span>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-lines"></span>
                </button>
            </div>

            <div *ngIf="isInternalLicenseSwitchAvailable && displayNewHomePage" class="premium">
                <div class="text" l10n="Agito.Hilti.Profis3.Main.Premium"></div>
                <pe-switch-button [checked]="isLicensePremium" (valueChange)="switchInternalLicense()"></pe-switch-button>
            </div>

            <span *ngIf="designName != null" class="project-design-name" [ngbTooltip]="designName" placement="bottom">{{designName}}</span>

            <div *ngIf="openDesignSettings != null" class="tooltip-wrapper"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.DesignOptions.Settings' | l10n: localizationService.selectedLanguage"
                placement="bottom">
                <button id="main-design-info-edit-button" class="document-design-button" type="button"
                    (click)="openDesignSettings()"
                    [disabled]="isOpenDesignSettingsDisabled">
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-settings"></span>
                </button>
            </div>

            <div *ngIf="openSaveAsTemplate != null" class="tooltip-wrapper" [ngbTooltip]="openSaveAsTemplateTooltip()" placement="bottom">
                <button id="main-design-save-as-template-button" class="document-design-button" type="button"
                    (click)="openSaveAsTemplate()" [disabled]="isOpenSaveAsTemplateDisabled"
                    *ngIf="!isOpenSaveAsTemplateHidden">
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-new-document"></span>
                </button>
            </div>
        </div>

        <div *ngIf="isInternalLicenseSwitchAvailable && !displayNewHomePage" class="header-center-button-wrapper header-center-button-wrapper-right">
            <button id="home-header-internal-license-switch-button" class="header-center-button"
                type="button" (click)="switchInternalLicense()">
                <span class="header-center-button-text">{{internalLicenseSwitchButtonText}}</span>
            </button>
        </div>

        <div class="upgrade-wrapper header-center-button-wrapper-right"
                *ngIf="userSettingsService.isUpgradeLicenseAvailable() && !licenseService.floatingLimitReached && displayNewHomePage">
                <button id="main-upgrade-to-premium-button" class="upgrade-button"
                    type="button" (click)="upgradeLicense()">
                    <span class="text">{{comparisonRedirectHeaderText}}</span>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-right-arrow"></span>
                </button>
        </div>

        <div *ngIf="isAskHiltiAvailable && !displayNewHomePage" class="header-center-button-wrapper header-center-button-wrapper-right">
            <button id="project-and-design-header-ask-hilti-button" class="header-center-button"
                type="button" (click)="openAskHilti()">
                <span class="header-center-button-text" l10n="Agito.Hilti.Profis3.Region.Link.AskHilti"></span>
            </button>
        </div>
    </div>

    <!-- HEADER RIGHT -->
    <div class="header header-right" *ngIf="!displayNewHomePage">
        <div class="border"></div>

        <span class="user-name" [ngbTooltip]="userName()" placement="bottom">{{userName()}}</span>

        <div class="dropdown-menu-container" ngbDropdown placement="bottom-right" (openChange)="menuToggled($event)" #menuContainerDropdown="ngbDropdown">
            <button id="project-and-design-header-right-user-options-dropdown-button" type="button"
                class="dropdown-menu-button" ngbDropdownToggle>
                <span class="pe-ui-common-sprite pe-ui-common-sprite-dots"></span>
                <span class="dropdown-menu-red-dot" *ngIf="showReleaseNotesDot"></span>
            </button>

            <div class="header-right-menu" ngbDropdownMenu>
                <button id="project-and-design-header-right-user-options-settings-button"
                    class="dropdown-item-button settings" type="button" (click)="openUserSettings()"
                    l10n="Agito.Hilti.Profis3.Main.UserOptions.MyProfile" ngbDropdownItem>
                </button>

                <button id="project-and-design-header-right-user-options-manage-hilti-account-button"
                    *ngIf="showManageHiltiAccount"
                    class="dropdown-item-button manage-hilti-account" type="button" (click)="openManageHiltiAccount()"
                    l10n="Agito.Hilti.Profis3.Main.UserOptions.ManageHiltiAccount" ngbDropdownItem>
                </button>

                <div ngbDropdown placement="left-top"
                        #headerRightUserOptionsApplicationSettingsDropdown="ngbDropdown"
                        (mouseenter)="headerRightUserOptionsApplicationSettingsDropdown.open()"
                        (mouseleave)="headerRightUserOptionsApplicationSettingsDropdown.close()">

                        <button id="project-and-design-header-right-user-options-application-settings"
                            class="application-settings" (click)="$event.stopPropagation();"
                            type="button" l10n="Agito.Hilti.Profis3.Main.UserOptions.ApplicationSettings"
                            ngbDropdownItem ngbDropdownToggle>
                        </button>

                        <div class="dropdown-menu" ngbDropdownMenu>
                            <button id="project-and-design-header-left-options-report-templates-button"
                                class="report-templates" (click)="openReportTemplates()"
                                type="button" l10n="Agito.Hilti.Profis3.Main.DesignOptions.ReportTemplates"
                                ngbDropdownItem>
                            </button>

                            <button *ngIf="startTour != null" id="project-and-design-header-left-options-tour-button"
                                class="tour" (click)="startTour()"
                                l10n="Agito.Hilti.Profis3.Main.DesignOptions.VirtualTour" type="button"
                                ngbDropdownItem>
                            </button>

                            <button id="project-and-design-header-left-options-support-button"
                                class="support" type="button" *ngIf="!isSupportLinkHidden()"
                                [attr.disabled]="isSupportLinkDisabled() ? true : null" (click)="openSupportLink()"
                                [ngbTooltip]="supportLinkTooltip()" placement="right"
                                l10n="Agito.Hilti.Profis3.Main.UserOptions.ContactUs" ngbDropdownItem>
                            </button>

                            <div ngbDropdown placement="left-top"
                                #headerRightUserOptionsUserManualDropdown="ngbDropdown"
                                (mouseenter)="headerRightUserOptionsUserManualDropdown.open()"
                                (mouseleave)="headerRightUserOptionsUserManualDropdown.close()">

                                <button id="project-and-design-header-left-user-options-user-manuals"
                                    class="application-settings"
                                    (click)="$event.stopPropagation();" type="button"
                                    l10n="Agito.Hilti.Profis3.Support.Info.UserManual.ButtonText"
                                    ngbDropdownItem ngbDropdownToggle>
                                </button>

                                <div class="dropdown-menu" ngbDropdownMenu>
                                    <button id="project-and-design-header-left-options-user-manual-pe"
                                        class="link" type="button"
                                        (click)="openUserManual()"
                                        l10n="Agito.Hilti.Profis3.Support.Info.UserManual.PE.ButtonText"
                                        ngbDropdownItem>
                                    </button>
                                </div>
                            </div>

                            <button id="main-options-dropdown-general-notes-button"
                                class="link" type="button" *ngIf="openGeneralNotes != null && generalNotesVisible"
                                l10n="Agito.Hilti.Profis3.Main.DesignOptions.GeneralNotes"
                                (click)="openGeneralNotes()" ngbDropdownItem>
                            </button>

                            <button [attr.id]="'main-options-dropdown-link-' + link.id"
                                class="link" type="button"
                                [l10n]="link.nameResourceKey"
                                (click)="onLinkClick(link)"
                                *ngFor="let link of regionLinks; trackBy: regionLinksTrackBy" ngbDropdownItem>
                            </button>

                            <button id="project-and-design-header-left-options-tour-button-hilti-data-privacy-url"
                                class="link" type="button"
                                l10n="Agito.Hilti.Profis3.Region.Link.HiltiDataPrivacyUrl"
                                (click)="openHiltiDataPrivacyUrl()" ngbDropdownItem>
                            </button>

                            <button id="project-and-design-header-right-user-options-user-agreement-settings-button"
                                class="user-agreement-settings" type="button"
                                (click)="openUserAgreementSettings()"
                                l10n="Agito.Hilti.Profis3.Main.UserOptions.PrivacySettings"
                                ngbDropdownItem>
                            </button>

                            <button id="project-and-design-header-right-user-options-user-agreement-document"
                                type="button" (click)="openUserAgreementDocument()"
                                l10n="Agito.Hilti.Profis3.UserAgreement.ButtonText" ngbDropdownItem>
                            </button>

                            <button id="project-and-design-header-right-user-options-subject-data-rights-button"
                                class="subject-data-rights" type="button"
                                *ngIf="showSubjectDataRights" (click)="openSubjectDataRights()"
                                l10n="Agito.Hilti.Profis3.Main.UserOptions.DataRights" ngbDropdownItem>
                            </button>

                            <button id="main-options-dropdown-license-button" class="license"
                                type="button" (click)="open3rdPartyLibraries()"
                                l10n="Agito.Hilti.Profis3.Main.License" ngbDropdownItem>
                            </button>
                        </div>
                    </div>

                <button id="project-and-design-header-right-user-options-create-desktop-shortcut"
                    class="dropdown-item-button" type="button" *ngIf="!this.offlineService.isOffline" (click)="openShortcutIconPopup()"
                    l10n="Agito.Hilti.Profis3.Main.UserOptions.CreateDesktopShortcut" ngbDropdownItem>
                </button>

                <button id="project-and-design-header-right-user-options-whats-new-button"
                    *ngIf="!offlineService.isOffline"
                    class="dropdown-item-button no-underline" type="button" (click)="openWhatsNew()" ngbDropdownItem>
                    <span l10n="Agito.Hilti.Profis3.Main.UserOptions.WhatsNew"></span>
                    <span class="dropdown-item-red-dot" *ngIf="showReleaseNotesDot"></span>
                </button>

                <button id="project-and-design-header-right-user-options-upgrade-pe" class="dropdown-item-button"
                    type="button" (click)="openUpgradePE()"
                    *ngIf="hasUpgradePELink && userSettingsService.isUpgradeLicenseAvailable() && !licenseService.floatingLimitReached"
                    l10n="Agito.Hilti.Profis3.Main.UserOptions.UpgradePE" ngbDropdownItem>
                </button>

                <!-- Marketing Campaign Button -->
                <button id="project-and-design-header-right-user-options-marketing-campaign-button"
                    class="dropdown-item-button user-agreement-settings" type="button"
                    (click)="openMarketingCampaigns()" l10n="Agito.Hilti.Profis3.Main.UserOptions.MarketingCampaign"
                    *ngIf="openMarketingCampaigns != null && hasMarketingCampaigns" ngbDropdownItem>
                </button>

                <!-- Software Version Button -->
                <button id="project-and-design-header-right-version-details-button"
                    *ngIf="displayVersions"
                    class="dropdown-item-button" type="button" (click)="openVersionDetails()"
                    l10n="Agito.Hilti.Profis3.Main.UserOptions.VersionDetails" ngbDropdownItem>
                </button>

                <!--Launch Darkly Flags Button -->
                <button id="project-and-design-header-right-user-options-Launch-Darkly-Flags"
                    *ngIf="displayFeatureFlags"
                    class="dropdown-item-button" type="button" (click)="openLDFlags()"
                    l10n="Agito.Hilti.Profis3.Main.UserOptions.LDFlags" ngbDropdownItem>
                </button>

                <!-- Log Out Button -->
                <button id="project-and-design-header-right-user-options-logout-button"
                    class="dropdown-item-button logout" type="button" *ngIf="!this.offlineService.isOffline" (click)="logout()"
                    l10n="Agito.Hilti.Profis3.Main.UserOptions.Logout" ngbDropdownItem>
                </button>
            </div>
        </div>
    </div>

    <!-- NEW HEADER RIGHT -->
    <div class="header header-right-new" *ngIf="displayNewHomePage">
        <div class="border"></div>

        <!-- Settings Button -->
        <button id="project-and-design-header-right-settings-button" type="button" class="header-right-button"
            *ngIf="openApplicationSettings != null" (click)="openApplicationSettings()" [disabled]="isDisabled">
            <div class="pe-ui-common-sprite pe-ui-common-sprite-Settings-medium"></div>
            <div class="truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.Header.Settings"></div>
        </button>

        <!-- Support Button -->
        <div ngbDropdown class="dropdown-menu-container support-menu-container" placement="bottom-right"
            (openChange)="menuToggled($event, support)" #headerRightSupportMenuDropdown="ngbDropdown">

            <button id="project-and-design-header-right-support-button" type="button" class="header-right-button"
                [ngClass]="{ 'selected' : userOption == support }" ngbDropdownToggle [disabled]="isDisabled">
                <div class="pe-ui-common-sprite pe-ui-common-sprite-support"></div>
                <div class="truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.Header.Support"></div>
            </button>

            <div class="header-right-menu-new" ngbDropdownMenu>
                <button *ngIf="isLicensePremium" id="project-and-design-header-right-user-options-send-feedback" class="dropdown-item-button"
                    type="button" (click)="openFeedbackForm()" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-feedback"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.SendFeedback"></span>
                </button>
                <button id="project-and-design-header-right-user-options-contact-us" *ngIf="!isSupportLinkHidden()"
                    [attr.disabled]="isSupportLinkDisabled() ? true : null" (click)="openSupportLink()"
                    class="dropdown-item-button" type="button" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-contact"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.ContactUs"></span>
                </button>
                <button id="project-and-design-header-right-user-options-create-desktop-shortcut"
                    class="dropdown-item-button" type="button" *ngIf="!this.offlineService.isOffline"
                    (click)="openShortcutIconPopup()" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-shortcut-icon"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.CreateDesktopShortCut"></span>
                </button>
                <div ngbDropdown placement="left-top" #headerRightUserOptionsUserManualDropdown="ngbDropdown"
                    class="dropdown-sub" (mouseenter)="headerRightUserOptionsUserManualDropdown.open()"
                    (mouseleave)="headerRightUserOptionsUserManualDropdown.close()">

                    <button id="project-and-design-header-right-user-options-other-resources" class="dropdown-item-button"
                        (click)="$event.stopPropagation();" type="button" ngbDropdownItem ngbDropdownToggle>
                        <span class="pe-ui-common-sprite pe-ui-common-sprite-resources"></span>
                        <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.OtherResources"></span>
                        <span class="pe-ui-common-sprite pe-ui-common-sprite-arrow"></span>
                    </button>

                    <div class="dropdown-menu margin" ngbDropdownMenu>
                        <button id="project-and-design-header-right-user-options-BIM-CAD-library" class="link truncate-ellipsis" type="button"
                            (click)="onLinkClick(this.HiltiBIMCadLibrary)" *ngIf="this.HiltiBIMCadLibrary"
                            [l10n]="this.HiltiBIMCadLibrary.nameResourceKey" ngbDropdownItem>
                        </button>
                        <button id="project-and-design-header-right-user-options-user-manual-pe" class="link truncate-ellipsis" type="button"
                            (click)="openUserManual()" l10n="Agito.Hilti.Profis3.Main.UserOptions.PROFIS.Manual"
                            ngbDropdownItem>
                        </button>
                        <button id="project-and-design-header-right-user-options-online-technical-library" class="link truncate-ellipsis"
                            type="button" (click)="onLinkClick(this.onlineTechnicalLibrary)"
                            *ngIf="this.onlineTechnicalLibrary" [l10n]="this.onlineTechnicalLibrary.nameResourceKey"
                            ngbDropdownItem>
                        </button>
                    </div>
                </div>
                <button id="project-and-design-header-right-version-details-button"
                    *ngIf="displayVersions" class="dropdown-item-button truncate-ellipsis" type="button"
                    (click)="openVersionDetails()" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-contact"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.VersionDetails"></span>
                </button>
                <button id="project-and-design-header-right-user-options-Launch-Darkly-Flags"
                    *ngIf="displayFeatureFlags" class="dropdown-item-button truncate-ellipsis" type="button"
                    (click)="openLDFlags()" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-contact"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.LDFlags"></span>
                </button>
            </div>
        </div>

        <!-- Learn Button -->
        <div ngbDropdown class="dropdown-menu-container learn-menu-container" placement="bottom-right" #headerRightLearnDropdown="ngbDropdown"
            (openChange)="menuToggled($event, learn)">
            <button id="project-and-design-header-right-e-learn-button" type="button" class="header-right-button"
                [ngClass]="{ 'selected' : userOption == learn }" ngbDropdownToggle [disabled]="isDisabled">
                <div class="pe-ui-common-sprite pe-ui-common-sprite-e-learning"></div>
                <div class="truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.Header.Learn"></div>
            </button>

            <div class="header-right-menu-new" ngbDropdownMenu>
                <button *ngIf="eLearningVisible" id="project-and-design-header-right-user-options-e-trainings"
                    class="dropdown-item-button" type="button" (click)="openELearning()" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-trainings"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.Engineering.Trainings"></span>
                </button>
                <button *ngIf="isAskHiltiAvailable" id="project-and-design-header-right-user-options-ask-hilti"
                    class="dropdown-item-button" type="button" (click)="openAskHilti()" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-ask-hilti"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Region.Link.AskHilti"></span>
                </button>
                <button *ngIf="startTour" id="project-and-design-header-right-user-options-virtual-tours"
                    class="dropdown-item-button tour" (click)="startVirtualTour()" type="button" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-tour"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.VirtualTours"></span>
                </button>
                <button *ngIf="openGeneralNotes != null && generalNotesVisible" id="project-and-design-header-right-user-options-general-notes"
                    class="dropdown-item-button" (click)="openGeneralNotes()" type="button" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-general-notes"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.DesignOptions.GeneralNotes"></span>
                </button>
            </div>
        </div>

         <!-- Updates Button -->
        <button id="project-and-design-header-right-updates-button" type="button" class="header-right-button"
            *ngIf="!offlineService.isOffline" (click)="openWhatsNew()" [disabled]="isDisabled">
            <div class="pe-ui-common-sprite pe-ui-common-sprite-notifications"></div>
            <div class="truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.Header.Updates"></div>
        </button>

         <!-- Profile Button -->
         <div ngbDropdown class="dropdown-menu-container profile-menu-container" placement="bottom-right" #headerRightProfileDropdown="ngbDropdown"
            (openChange)="menuToggled($event, profile)">
            <button id="project-and-design-header-right-profile-button" type="button" class="header-right-button"
            [ngClass]="{ 'selected' : userOption == profile }" ngbDropdownToggle [disabled]="isDisabled">
                <div class="pe-ui-common-sprite pe-ui-common-sprite-profile"></div>
                <div class="truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.Header.Profile"></div>
            </button>

            <div class="header-right-menu-new" ngbDropdownMenu>
                <button id="project-and-design-header-right-user-options-my-profile" class="dropdown-item-button"
                    type="button" (click)="openUserSettings()" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-my-profile"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.MyProfile"></span>
                </button>
                <button id="project-and-design-header-right-user-options-manage-account" class="dropdown-item-button"
                    type="button" (click)="openManageHiltiAccount()" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-manage-account"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.ManageAccount"></span>
                </button>
                <button id="project-and-design-header-right-user-options-report-templates" class="dropdown-item-button"
                    type="button" (click)="openReportTemplates()" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-templates"></span>
                    <span class="text truncate-ellipsis"  l10n="Agito.Hilti.Profis3.Main.DesignOptions.ReportTemplates.Premium"></span>
                </button>
                <div ngbDropdown placement="left-top" #headerUserOptionsUserManualDropdown="ngbDropdown"
                        class="dropdown-sub" (mouseenter)="headerUserOptionsUserManualDropdown.open()"
                        (mouseleave)="headerUserOptionsUserManualDropdown.close()">

                    <button id="project-and-design-header-right-user-options-legal" class="dropdown-item-button"
                        (click)="$event.stopPropagation();" type="button" ngbDropdownItem ngbDropdownToggle>
                        <span class="pe-ui-common-sprite pe-ui-common-sprite-legal"></span>
                        <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.Legal"></span>
                        <span class="pe-ui-common-sprite pe-ui-common-sprite-arrow"></span>
                    </button>

                    <div class="dropdown-menu margin" ngbDropdownMenu>
                        <button id="project-and-design-right-user-options-hilti-data-privacy-url"
                            class="link truncate-ellipsis" type="button"
                            l10n="Agito.Hilti.Profis3.Region.Link.HiltiDataPrivacyUrl"
                            (click)="openHiltiDataPrivacyUrl()" ngbDropdownItem>
                        </button>
                        <button id="project-and-design-right-user-options-privacy-settings"
                            class="link truncate-ellipsis" type="button"
                            l10n="Agito.Hilti.Profis3.Main.UserOptions.Privacy"
                            (click)="openUserAgreementSettings()" ngbDropdownItem>
                        </button>
                        <button id="project-and-design-right-user-options-subject-data-rights"
                            class="link truncate-ellipsis" type="button"
                            l10n="Agito.Hilti.Profis3.Main.UserOptions.SubjectDataRights"
                            *ngIf="showSubjectDataRights" (click)="openSubjectDataRights()" ngbDropdownItem>
                        </button>
                        <button id="project-and-design-right-user-options-license-agreement"
                            class="link truncate-ellipsis" type="button"
                            l10n="Agito.Hilti.Profis3.Main.UserOptions.LicenseAgreement"
                            (click)="openUserAgreementDocument()" ngbDropdownItem>
                        </button>
                        <button id="project-and-design-right-user-options-third-party-libraries"
                            class="link truncate-ellipsis" type="button" (click)="open3rdPartyLibraries()"
                            l10n="Agito.Hilti.Profis3.Main.UserOptions.ThirdPartyLibraries"
                             ngbDropdownItem>
                        </button>
                    </div>
                </div>
                <button id="project-and-design-header-right-user-options-logout" class="dropdown-item-button"
                    type="button" *ngIf="!this.offlineService.isOffline" (click)="logout()" ngbDropdownItem>
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-logout"></span>
                    <span class="text truncate-ellipsis" l10n="Agito.Hilti.Profis3.Main.UserOptions.Logout.New"></span>
                </button>
            </div>
        </div>
    </div>
</div>
