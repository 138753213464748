import { Design, IBaseDesign } from '../entities/design';
import { Project } from '../entities/project';

export const enum DocumentAccessMode {
    Open,   // open document
    Update, // update document
}

/**
 *  The interface for basic design metadata
 */
export interface DesignExternalMetaData {
    id: string;
    name: string;
    region: number;
    standard: number;
    designType: number;
    approvalNumber?: string;
    designMethod?: number;
    productName?: string;
}

/**
 *  The interface for basic list items.
 */
export interface IDesignListItem extends IBaseDesign {
    metaData: DesignExternalMetaData;
}

export interface IDefferedData<TProjectDesign, TDetailedDisplayDesign> {
    designId: string;
    projectId: string;
    designName: string;
    contentOverride: TProjectDesign;
    metadataOverride: DesignExternalMetaData;
    unlock: boolean;
    displayDesign: TDetailedDisplayDesign;
    exclusiveLock: boolean;
    documentAccessMode: DocumentAccessMode;
}


export interface IDocumentServiceDesign {
    documentid: string;
    name: string;
    projectid: string;
    parentProjectId: string;
}


/**
 * A base class for all can't perform action responses.
 */
export abstract class CantPerformActionReason {

}

/**
 * Instance of class derived from this class are sometimes be returned when opening a design insted of the design.
 */
export abstract class CantOpenDesignReason extends CantPerformActionReason {

}

/**
 * CantOpen design because another user has locked it.
 */
export class CantOpenDesignBecauseLockedByOtherUser extends CantOpenDesignReason {
    /**
     * User-name of the user that locked the design.
     */
    public username?: string;
}

/**
 * Cant archive design because another user has locked it.
 */
export class CantArchiveDesignBecauseLockedByOtherUser extends CantOpenDesignReason {
    /**
     * User-name of the user that locked the design.
     */
    public username?: string;
}

/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantDeleteProjectsBecauseDocumentInUse extends CantPerformActionReason {
    /**
     * A list of documents locked together with document names and users that have the files locked.
     */
    public documentsInQuestion: CantPerformActionReasonProblematicDocumentInformation[] = [];
}

/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantRestoreProjectsBecauseDocumentInUse extends CantPerformActionReason {
    /**
     * A list of documents locked together with document names and users that have the files locked.
     */
    public documentsInQuestion: CantPerformActionReasonProblematicDocumentInformation[] = [];
}

/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantArchiveProjectsBecauseDocumentInUse extends CantPerformActionReason {
    /**
     * A list of documents locked together with document names and users that have the files locked.
     */
    public documentsInQuestion: CantPerformActionReasonProblematicDocumentInformation[] = [];
}

/**
 * Just holding information for each locked document that cussed the problem
 */
export class CantPerformActionReasonProblematicDocumentInformation {
    public designId?: string;
    public username?: string;
    public document?: string;
}

/**
 * Document service base
 */
export abstract class DocumentServiceBase {
    public abstract findDesignById(designId: string): IDesignListItem;
    public abstract findProjectById(projectId: string): Project;
    public abstract addDesignCommon(projectId: string, design: Design, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItem>;
    public abstract updateDesignWithNewContentCommon<TDisplayDesign>(design: Design, displayDesign: TDisplayDesign, unlock: boolean, exclusiveLock: boolean, documentAccessMode?: DocumentAccessMode): Promise<void>;
    public abstract updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void>;
    public abstract publish(id: string): Promise<void>;
    public abstract findProjectByDesignId(designId: string): Project;
    public abstract get projects(): Record<string, Project>;
    public abstract uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void>;
    public abstract copyDesign(documentId: string, documentName: string, projectId: string): Promise<void>;
    public abstract moveDesign(documentId: string, projectId: string): Promise<void>;
    public abstract openDesignExclusive<TProjectDesign>(design: IBaseDesign, adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign>;
    public abstract get draftsProject(): Project;
    public abstract getSessionKeyForDesign(designId: string): string;
}

export class DocumentServiceInjected extends DocumentServiceBase{
    protected baseService!: DocumentServiceBase;

    public setBaseService(baseService: DocumentServiceBase) {
        this.baseService = baseService;

        this.findDesignById = baseService.findDesignById.bind(baseService);
        this.findProjectById = baseService.findProjectById.bind(baseService);
        this.addDesignCommon = baseService.addDesignCommon.bind(baseService);
        this.updateDesignWithNewContentCommon = baseService.updateDesignWithNewContentCommon.bind(baseService);
        this.updateDesignThumbnailImage = baseService.updateDesignThumbnailImage.bind(baseService);
        this.publish = baseService.publish.bind(baseService);

        this.findProjectByDesignId = baseService.findProjectByDesignId.bind(baseService);
        this.uploadDesignImage = baseService.uploadDesignImage.bind(baseService);
        this.copyDesign = baseService.copyDesign.bind(baseService);
        this.moveDesign = baseService.moveDesign.bind(baseService);
        this.openDesignExclusive = baseService.openDesignExclusive.bind(baseService);
        this.getSessionKeyForDesign = baseService.getSessionKeyForDesign.bind(baseService);
    }

    // DocumentServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public get draftsProject() {
        return this.baseService.draftsProject;
    }

    public findDesignById(_designId: string): IDesignListItem {
        return {} as IDesignListItem;
    }

    public findProjectById(_projectId: string): Project {
        return {} as Project;
    }

    public addDesignCommon(_projectId: string, _design: Design, _canGenerateUniqueName: boolean, _ignoreConflict: boolean): Promise<IDesignListItem> {
        return {} as Promise<IDesignListItem>;
    }

    public updateDesignWithNewContentCommon<TDisplayDesign>(_design: Design, _displayDesign: TDisplayDesign, _unlock: boolean, _exclusiveLock: boolean, _documentAccessMode?: DocumentAccessMode): Promise<void> {
        return {} as Promise<void>;
    }

    public updateDesignThumbnailImage(_designId: string, _thumbnailContent: string, _respond: boolean): Promise<void> {
        return {} as Promise<void>;
    }

    public publish(_id: string): Promise<void> {
        return {} as Promise<void>;
    }

    public findProjectByDesignId(_designId: string) {
        return {} as Project;
    }

    public get projects() {
        return {} as Record<string, Project>;
    }

    public uploadDesignImage(_designId: string, _imageContent: string, _thumbnailContent: string): Promise<void> {
        return  {} as Promise<void>;
    }

    public copyDesign(_documentId: string, _documentName: string, _projectId: string): Promise<void> {
        return  {} as Promise<void>;
    }

    public moveDesign(_documentId: string, _projectId: string): Promise<void> {
        return  {} as Promise<void>;
    }

    public openDesignExclusive<TProjectDesign>(_design: IBaseDesign, _adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        return {} as Promise<TProjectDesign>;
    }

    public getSessionKeyForDesign(_designId: string): string {
        return '';
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
