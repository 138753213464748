import { IValidationAdditionalData, IValidatorFn } from '../../helpers/validation-helper';
import { Tooltip } from '../content-tooltip/content-tooltip.common';
import { InfoPopup } from '../control-title/control-title.common';

export const enum TextBoxLook {
    Normal      = 'Normal',
    HiltiStyled = 'HiltiStyled',
    Large       = 'Large',
}

export const enum TextBoxMargin {
    Normal = 'Normal',
    None   = 'None',
}

export const enum TextBoxBorder {
    Normal = 'Normal',
    None   = 'None',
}

export const enum TextBoxDisplay {
    Normal = 'Normal',
    Bold   = 'Bold',
}

export const enum TextBoxAlign {
    Start   = 'Start',
    Center  = 'Center'
}

export type TextBoxType = 'text' | 'number' | 'password' | 'tel' | 'email';

export interface TextBoxProps {
    id?: string;
    title?: string;
    value?: string;
    blur?: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    type?: TextBoxType;
    minLength?: number;
    maxLength?: number;
    tooltip?: Tooltip;
    infoPopup?: InfoPopup;
    infoPopupTooltip?: Tooltip;
    submitted?: boolean;
    updateOnBlur?: boolean;
    debounceChanges?: boolean;
    preventFormSubmit?: boolean;
    stepValue?: number;

    look?: TextBoxLook;
    marginBottom?: TextBoxMargin;
    borderTop?: TextBoxBorder;
    borderBottom?: TextBoxBorder;
    borderLeft?: TextBoxBorder;
    borderRight?: TextBoxBorder;
    textAlign?: TextBoxAlign;
    display?: TextBoxDisplay;
    height?: number;
    width?: number;

    isValid?: boolean;
    validators?: IValidatorFn[];
    validationData?: IValidationAdditionalData;
}

export const valueChangeDelay = 200;
